import React, { FC } from 'react';
import './Resume.css';

interface ResumeProps { }

const Resume: FC<ResumeProps> = () => (
  <div className="Resume" data-testid="Resume">
    <section id="resume" className="grey-section">

      <div className="row section-intro">
        <div className="col-twelve">

          <h5>Resume</h5>
          <h1>More of my credentials.</h1>

          <p className="lead">
            
          </p>

        </div>
      </div>

      <div className="row resume-timeline">

        <div className="col-twelve resume-header">

          <h2>Work Experience</h2>

        </div>

        <div className="col-twelve">

          <div className="timeline-wrap">

            <div className="timeline-block">

              <div className="timeline-ico">
                <i className="fa fa-laptop"></i>
              </div>

              <div className="timeline-header">
                <h3>Internship</h3>
                <p>July 2023 - Okt. 2023</p>
              </div>

              <div className="timeline-content">
                <h4>Digital Automotive</h4>
                <p>Backend Developer</p>
              </div>

            </div>

            <div className="timeline-block">

              <div className="timeline-ico">
                <i className="fa fa-laptop"></i>
              </div>

              <div className="timeline-header">
                <h3>Internship</h3>
                <p>Mai 2021 - Aug. 2021</p>
              </div>

              <div className="timeline-content">
                <h4>Mangold International</h4>
                <p>
                  ► Developed a remote control unit for a video recording and playback system using Raspberry Pi<br />
                  ► Created a Firemonkey desktop application for controlling a system via UDP commands<br />
                  ► Developed a cross-platform application with Firemonkey for importing various text-based data formats (.json, .xml, .csv, .xls, etc.) and converting them into a defined target format.
                </p>
              </div>
            </div>

            <div className="timeline-block">

              <div className="timeline-ico">
                <i className="fa fa-laptop"></i>
              </div>

              <div className="timeline-header">
                <h3>Internship</h3>
                <p>Aug. 2020 - Nov. 2020</p>
              </div>

              <div className="timeline-content">
                <h4>Umami Ware GmbH</h4>
                <p>
                  IHK Final Project: Developed a component for graphical representation of new customer acquisition using Angular, and created a regression model for growth forecasting using TensorFlow.
                </p>
              </div>

            </div>

          </div>

        </div>

      </div>

      <div className="row resume-timeline">

        <div className="col-twelve resume-header">

          <h2>Education</h2>

        </div>

        <div className="col-twelve">

          <div className="timeline-wrap">

            <div className="timeline-block">

              <div className="timeline-ico">
                <i className="fa fa-graduation-cap"></i>
              </div>

              <div className="timeline-header">
                <h3>Bachelor Degree</h3>
                <p>Oct. 2023 - Present</p>
              </div>

              <div className="timeline-content">
                <h4>Technische Universität München</h4>
                <p>Informatik Studium</p>
              </div>

            </div>

            <div className="timeline-block">

              <div className="timeline-ico">
                <i className="fa fa-graduation-cap"></i>
              </div>

              <div className="timeline-header">
                <h3>Nachholen des Abiturs an BOS</h3>
                <p>Sept. 2021 - June 2023</p>
              </div>

              <div className="timeline-content">
                <h4>BOS Deggendorf</h4>
                <p>
                  After completing my training as an IT specialist, I decided to further pursue my interest in the technical aspects of computer science by obtaining my abitur diploma and continuing my studies at a University.
                </p>
              </div>

            </div>

            <div className="timeline-block">

              <div className="timeline-ico">
                <i className="fa fa-laptop"></i>
              </div>

              <div className="timeline-header">
                <h3>Vocational Training</h3>
                <p>2018 - 2021</p>
              </div>

              <div className="timeline-content">
                <h4>IHK vocational Training as "Fachinformatiker Anwendungsentwicklung"</h4>
                <p>Completed vocational training as an IT specialist in application development at Berufsakademie Passau, achieving an IHK grade of "Very Good" and receiving a State Award.</p>
              </div>

            </div>

          </div>

        </div>

      </div>

      <div className="row resume-timeline">

        <div className="col-twelve resume-header">

          <h2>Special</h2>

        </div>

        <div className="col-twelve">

          <div className="timeline-wrap">

            <div className="timeline-block">

              <div className="timeline-ico">
                <i className="fa fa-graduation-cap"></i>
              </div>

              <div className="timeline-header">
                <h3>Bachelor Degree</h3>
                <p>Nov. 2023 - July 2024</p>
              </div>

              <div className="timeline-content">
                <h4>Advisor Soft Skills programm</h4>
                <p>
                  I participated in a special two-semester program offered by TUM, focusing on developing soft skills. The program covered key areas such as teamwork, leadership, project management, moderation, rhetoric, communication techniques, time management, learning methods, conflict resolution, and project work.
                </p>
              </div>

            </div>

          </div>

        </div>

      </div>

    </section >
  </div >
);

export default Resume;
