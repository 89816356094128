import React, { FC } from 'react';
import './ProjectPortfolio.css';
import AmazonReleaseDateTrackerPortfolioProject from '../AmazonReleaseDateTrackerPortfolioProject/AmazonReleaseDateTrackerPortfolioProject';

interface ProjectPortfolioProps { }

const ProjectPortfolio: FC<ProjectPortfolioProps> = () => (
  <div className="ProjectPortfolio" data-testid="ProjectPortfolio">
    <section id="portfolio">

      <div className="row section-intro">
        <div className="col-twelve">

          <h5>Portfolio</h5>
          <h1>Check Out Some of My Works.</h1>

          <p className="lead"></p>

        </div>
      </div>

      <div className="row portfolio-content">
        <hr></hr>
        <AmazonReleaseDateTrackerPortfolioProject />
        <hr></hr>
      </div>
    </section>
  </div>
);

export default ProjectPortfolio;
