import React, { FC } from 'react';
import './IntroSection.css';

interface IntroSectionProps { }

const IntroSection: FC<IntroSectionProps> = () => {

  const aboutMeClicked = () => {

  }

  return (

    <div className="IntroSection" data-testid="IntroSection">
      <section id="intro">

        <div className="intro-overlay"></div>

        <div className="intro-content">
          <div className="row">

            <div className="col-twelve">

              <h5>Hello, World.</h5>
              <h1>I'm Alexander Eineder.</h1>

              <p className="intro-position">
                <span>Software-Developer</span>
                <span>TUM-Student</span>
              </p>

              <a className="button stroke smoothscroll" href="#resume" title="" onClick={aboutMeClicked}>More About Me</a>

            </div>

          </div>
        </div>

        <ul className="intro-social">
          <li><a href="https://www.linkedin.com/in/alexander-eineder-7241841aa/"><i className="fa fa-linkedin"></i></a></li>
          <li><a href="https://github.com/aeAlex"><i className="fa fa-github"></i></a></li>
        </ul>

      </section>
    </div>
  );
}

export default IntroSection;
