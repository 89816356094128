import React, { useEffect } from 'react';
import './App.css';
import './assets/css/base.css';
import './assets/css/main.css';
import './assets/css/vendor.css';
import './assets/css/fonts.css';
import IntroSection from './components/IntroSection/IntroSection';
import Resume from './components/Resume/Resume';
import ContactSection from './components/ContactSection/ContactSection';
import ProjectPortfolio from './components/ProjectPortfolio/ProjectPortfolio';
import FootBar from './components/FootBar/FootBar';

function App() {
  useEffect(() => {
    document.title = "Eineder"
  }, []);
  return (
    <div className="App">
      {/*<TopBar />*/}
      <IntroSection />
      <ProjectPortfolio />
      <Resume />
      <ContactSection />
      <FootBar />
    </div>
  );
}

export default App;
