import React, { FC } from 'react';
import './ContactSection.css';

interface ContactSectionProps { }

const ContactSection: FC<ContactSectionProps> = () => (
  <div className="ContactSection" data-testid="ContactSection">

    <section id="contact">

      <div className="row section-intro">
        <div className="col-twelve">

          <h5>Contact</h5>
          <h1>I'd Love To Hear From You.</h1>

          <p className="lead">
            Want to hire me? Have a question about one of my projects? Or just want to chat? <br />
            Feel free to contact me.
          </p>

        </div>
      </div>

      <div className="row contact-info">

        <div className="col-four tab-full">

          <div className="icon">
            <a href="https://github.com/aeAlex"><i className="fa fa-github"></i></a>
          </div>

          <h5>Github</h5>

        </div >


        <div className="col-four tab-full collapse">

          <div className="icon">
            <i className="icon-mail"></i>
          </div>

          <h5>Email Me At</h5>

          <p>eineder_alexander@gmx.de<br />
          </p>

        </div>

        <div className="col-four tab-full">

          <div className="icon">
            <a href="https://www.linkedin.com/in/alexander-eineder-7241841aa/"><i className="fa fa-linkedin"></i></a>
          </div>

          <h5>Linkedin</h5>

        </div >



      </div>


    </section >

  </div >
);

export default ContactSection;
