import React, { FC } from 'react';
import './AmazonReleaseDateTrackerPortfolioProject.css';

interface AmazonReleaseDateTrackerPortfolioProjectProps { }

const AmazonReleaseDateTrackerPortfolioProject: FC<AmazonReleaseDateTrackerPortfolioProjectProps> = () => (
  <div className="AmazonReleaseDateTrackerPortfolioProject" data-testid="AmazonReleaseDateTrackerPortfolioProject">
    <section id="ardt">
      <a href="https://github.com/aeAlex/AmazonReleaseDateTrackerAll"><h1><i className="fa fa-github"></i> Amazon Release Date Tracker </h1></a>
      <p>
        As an avid Kindle reader and Audible user, I found the existing tools offered by Amazon inadequate for tracking upcoming book releases. <br />
        To address this, I developed a custom tool tailored to my needs.
      </p>
      <h2>Full-Stack Implementation and Technology Overview</h2>
      <p>
        This project showcases my ability to work across the full stack, from frontend development and RESTful API design to database management and infrastructure automation with Docker.
      </p>
      <div className="container">
        <div className="three-columns">
          <div className="box">
            <h2>Frontend</h2>
            <img src={require('../../assets/images/ardt-frontend-example.png')} alt="Frontend Interface" />
            <p>A <strong>React</strong>-based <a href="http://einsoftdev.com/AmazonReleaseDateTracker/">frontend</a> that provides a user-friendly interface to display and manage the tracked books.</p>

          </div>
          <div className="box">
            <h2>Backend</h2>
            <img src={require('../../assets/images/ardt-backend-infra.png')} alt="Backend Architecture" />
            <p>An <strong>Express.js</strong> backend that acts as a RESTful API server, handling data retrieval, storage, and user authentication.</p>
          </div>
          <div className="box">
            <h2>Browser Extension</h2>
            <img src={require('../../assets/images/ardt-browser-extension-example.png')} alt="Browser Extension" />
            <p>A custom <strong>Browser Extension</strong> built with <strong>jQuery</strong>, allowing users to easily add books to the tracker directly from the Amazon book page with a single click.
              Functions trough the use of a clientside htmlscraper.</p>
          </div>
        </div>
      </div>
      <div>
        <h2>MySQL - Database</h2>
        <p>
          A <strong>MySQL-atabase</strong> for storing book and user data securely and reliably.
        </p>
      </div>
      <div>
        <h2>Nginx reverse proxy</h2>
        <p>
          An <strong>Nginx reverse proxy</strong> that efficiently routes requests to the appropriate service, whether it’s the frontend, backend, or database.
        </p>
      </div>
      <div>
        <h2>Nginx reverse proxy</h2>
        <p>
          Dockerized infrastructure using <strong>Docker Compose</strong> and custom <strong>Dockerfiles</strong> to manage the four containers: Frontend, Backend, Database, and Reverse Proxy.
        </p>
      </div>
      <div>
        Interested? Take a look at the code on <a href="https://github.com/aeAlex/AmazonReleaseDateTrackerAll">GitHub <i className="fa fa-github"></i></a>.
      </div>
    </section>
  </div>
);

export default AmazonReleaseDateTrackerPortfolioProject;
