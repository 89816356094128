import React, { FC } from 'react';
import './FootBar.css';

interface FootBarProps {}

const FootBar: FC<FootBarProps> = () => (
  <div className="FootBar" data-testid="FootBar">
       <footer>
     	<div className="row">

     		<div className="col-six tab-full pull-right social">

     			<ul className="footer-social">        
			      <li><a href="https://www.linkedin.com/in/alexander-eineder-7241841aa/"><i className="fa fa-linkedin"></i></a></li>
			      <li><a href="https://github.com/aeAlex"><i className="fa fa-github"></i></a></li>
			   </ul> 

	      </div>

      	<div className="col-eight tab-full">
	      	<div className="copyright">
		        	<span>Some of the design from: <a href="http://www.styleshout.com/">styleshout</a></span>      	
		         </div>		                  
	      	</div>

	      	<div id="go-top">
		         <a className="smoothscroll" title="Back to Top" href="#top"><i className="fa fa-long-arrow-up"></i></a>
		      </div>

      	</div>  	
   </footer>  
  </div>
);

export default FootBar;
